import Typography from '@material-ui/core/Typography';
import { TextField as TextBruh } from '@material-ui/core/';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';


function Text({ text }) {
  return (
    <Typography variant="h6"
      key={text}
      style={{
        userSelect: "none",
        marginRight: '10px', marginLeft: '10px', fontSize: '16px'
      }}
    >
      {text}
    </Typography>
  );
}

const StyledText = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
  },
  input: {
    color: 'white'
  }

})(TextBruh);

function Dropdown({ list, onSelect, placeHolder }) {
  return (
    <Autocomplete
      options={list}
      fullWidth={true}
      style={{ minWidth: '120px' }}
      onChange={(_, newValue) => onSelect(newValue)}
      disableClearable={true}
      renderInput={(params) => <StyledText {...params} label={placeHolder} />}
    />
  );

}

function TextField({ placeHolder, onChange }) {
  return (
    <StyledText
      placeholder={placeHolder}
      fullWidth={true}
      style={{ color: 'white', }}
      onChange={(event) => onChange(event.target.value)}
    />
  );
}


function splitTitle(title) {
  return title.replace('@', '|@|').replace('_', '|_|').split('|').filter(str => str !== '');
}

export default function OptionsTitle({ title, options, onSelect }) {
  const content = [];

  const titlePieces = splitTitle(title);

  let optionsIndex = 0;
  titlePieces.forEach((piece, index) => {
    switch (piece) {
      case '_':
        const optionsListA = options[optionsIndex++];
        content.push(<TextField key={index}
          placeHolder={optionsListA.placeHolder} onChange={(value) => onSelect(value, optionsListA.type)}
        />);
        break;
      case '@':
        const optionsList = options[optionsIndex++];
        content.push(<Dropdown key={index}
          type={optionsList.type} list={optionsList.list}
          index={optionsIndex} onSelect={(value) => onSelect(value, optionsList.type)}
          placeHolder={optionsList.placeHolder}
        />);
        break;
      default:
        content.push(<Text key={index} text={piece} />);
    }
  });

  return (
    <div>
      {content}
    </div>
  );
}