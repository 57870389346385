import { subscribe, send } from './socket';

const JSZip = require('jszip');
const download = require('downloadjs');

const baseMobList = require('../lists/mobList.json')
  .sort()
  .map(mob => mob.replace(' ', '_').toLowerCase());
export const itemList = require('../lists/itemList.json').sort();

function formatClause(clause) {
  switch (clause.condition.type) {
    case 'recipe':
      return {
        type: 'recipe',
        recipe: clause.data.recipe.map(item => item === null ? null : `minecraft:${item}`),
        result: {
          ...clause.data.result,
          item: `minecraft:${clause.data.result.item}`,
          count: parseInt(clause.data.result.count, 10)
        }
      }
    default:
      return {
        type: 'event',
        event: clause.condition.alias,
        commands: clause.statements.map(statement => ({
          type: statement.alias,
          ...statement.data
        }))
      };
  }
}

async function zipContent(pack) {
  const zip = new JSZip();
  for (const path in pack) zip.file(path, pack[path]);
  return zip.generateAsync({ type: "blob" });
}

async function saveFile(content, fileName, directory) {
  const file = await directory.getFileHandle(fileName, { create: true });
  const writable = await file.createWritable({ keepExistingData: false });
  await writable.write({ type: "write", data: content });
  await writable.close();
}

function clausesEmpty(clauses) {
  if (!clauses.length || clauses.length === 0)
    return true;
  return false;
}

function removeDir(files, dir) {
  const out = {};
  for (const key in files) {
    out[key.substring(dir.length + 1)] = files[key];
  }
  return out;
}

let PACK_NAME = undefined;
let DATAPACK_DIR = undefined;
export async function saveClauses(clauses) {
  if (clausesEmpty(clauses)) return

  if (!PACK_NAME) {
    const packName = prompt('NAME THE DATAPACK BRUH?');
    if (packName === null)
      return
    PACK_NAME = packName;
  }

  console.log(clauses.map(formatClause));
  // get pack and libraries objects
  send({
    action: 'create-datapack',
    packName: PACK_NAME,
    clauses: clauses.map(formatClause)
  });

  let pack = null;
  let libraries = null;
  let expectedFileCount = 0;
  let fileCount = 0;
  subscribe(async (data) => {
    if (data.action === 'datapack-begin') {
      pack = {};
      libraries = {};
      expectedFileCount = data.fileCount;
      // console.log('begin');
    } else if (data.action === 'datapack-end') {
      // console.log('end');
      if (fileCount !== expectedFileCount) {
        console.log('something kinda went wrong oof');
        return;
      }
      if (false) {
        if (!DATAPACK_DIR) {
          const datapackDir = await window.showDirectoryPicker();
          if (datapackDir === null)
            return
          DATAPACK_DIR = datapackDir;
        }

        const [packZip, librariesZip] = await Promise.all([
          zipContent(removeDir(pack, PACK_NAME)),
          zipContent(removeDir(libraries, 'corem'))
        ]);

        await Promise.all([
          saveFile(packZip, `${PACK_NAME}.zip`, DATAPACK_DIR),
          saveFile(librariesZip, 'corem.zip', DATAPACK_DIR)
        ]);
      } else {
        const zip = await zipContent({ ...pack, ...libraries });

        download(zip, `bundle.zip`, 'application/zip');
      }
    } else if (data.action === 'datapack-file') {
      fileCount++;
      // console.log('file', fileCount);
      if (data.path.startsWith('corem/')) {
        libraries[data.path] = data.contents;
      } else {
        pack[data.path] = data.contents;
      }
    } else {
      console.log('something went wrong', data.action); // shouldn't ever happen
    }
  });

}

const mobList = [...baseMobList];

const customEntities = {};
function addEntity(name, id) {
  const oldEntity = customEntities[id];
  let newMobList = mobList.filter((entity) => entity !== oldEntity);

  customEntities[id] = name;
  newMobList.push(name);

  mobList.splice(0, mobList.length);
  newMobList.forEach(element => {
    mobList.push(element);
  });

  console.log(mobList)
}

const conditionGlobals = {
  width: 200,
  height: 200
};

export const conditions = [{
  ...conditionGlobals,
  type: 'default',
  alias: 'minecraft.custom:minecraft.jump',
  text: 'When a player jumps',
}, {
  ...conditionGlobals,
  type: 'default',
  alias: 'minecraft.custom:minecraft.walk_one_cm',
  text: 'When a player walks',
}, {
  ...conditionGlobals,
  type: 'default',
  alias: 'minecraft.custom:minecraft.sprint_one_cm',
  text: 'When a player sprints',
}, {
  ...conditionGlobals,
  type: 'recipe',
  text: 'Craft Item',
}];

const statementGlobals = {
  width: 250,
  height: 50
};

export const statements = [{
  ...statementGlobals,
  type: 'options',
  alias: 'summon',
  title: 'Summon an entity',
  text: 'Summon @',
  options: [
    { type: 'entity', list: mobList, },
    { type: 'health' },
  ],
  data: {
    entity: 'slime', //default
  }
}, {
  ...statementGlobals,
  type: 'default',
  alias: 'summon',
  title: 'Smite',
  text: 'Smite',
  data: {
    entity: 'lightning_bolt'
  }
}, {
  ...statementGlobals,
  type: 'default',
  alias: 'summon',
  title: 'Explode',
  text: 'Explode',
  data: {
    entity: 'tnt'
  }
},];
