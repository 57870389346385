import React from 'react';

import Statement from './Statement';
import Clause from './Clause';

import { v4 as uuid4 } from "uuid";

const BOARD_ID = uuid4();

export default function Board({
  clauses, floatingStatements, setClause, floatStatement, deFloatStatement
}) {
  const collisionHandler = window.collisionHandler;
  const onTriggerEnter = (metadata, sameCollider) => {
    if (!sameCollider) floatStatement(metadata);
  };
  const onTriggerLeave = (metadata, sameCollider) => {
    if (!sameCollider) deFloatStatement(metadata.id);
  };

  if (!collisionHandler.colliderExists(BOARD_ID)) {
    collisionHandler.setCollider(
      { x: 0, y: 0, width: Number.MAX_SAFE_INTEGER, height: Number.MAX_SAFE_INTEGER },
      BOARD_ID,
      onTriggerEnter,
      onTriggerLeave,
      -1
    );
  }

  floatingStatements.forEach((statement) => collisionHandler.forceCollision(BOARD_ID, statement.id, statement));

  const dropStatement = (event, statement) => {
    const { x, y } = event.node.getBoundingClientRect();
    collisionHandler.doCollision(
      { x, y, width: 200, height: 50 },
      statement.id,
      statement
    );
  }

  return (<div >
    {
      clauses.map(clause => (
        <Clause key={clause.id} clause={clause} setClause={setClause} x={0} y={0} />
      ))
    }
    <br />
    {
      floatingStatements.map(statement => (
        <Statement statement={statement} width={200} height={50} onDrop={dropStatement} />
      ))
    }
  </div >);
}