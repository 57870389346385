import DefaultStatement from './statements/DefaultStatement';
import OptionsStatement from './statements/OptionsStatement';

export default function Statement({ statement, onDrop }) {
  switch (statement.type) {
    case 'options':
      return <OptionsStatement statement={statement} onDrop={onDrop} />;
    default:
      return <DefaultStatement statement={statement} onDrop={onDrop} />;
  }
}