import { useState } from 'react';

import Draggable from 'react-draggable';
import Typography from '@material-ui/core/Typography';
import { Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

import Statement from './Statement';
import OptionsTitle from './OptionsTitle';
import Crafting from './Crafting';

const style = (width) => ({
  cursor: 'pointer',
  position: 'absolute',
  width: `${width}px`,
  minHeight: `250px`,
  color: 'white',
  backgroundImage: 'url(./stone.png)',
  backgroundSize: 'cover',
  imageRendering: 'pixelated',
  filter: 'drop-shadow(0 0 0.75rem #000000aa)'
});

const textStyle = {
  marginLeft: '10px',
  marginRight: '10px',
  fontSize: '20px'
};

const listStyle = {
  padding: 0, listStyleType: 'none',
  'text-align': 'center',
};

const buttonStyle = {
  position: 'fixed',
  bottom: '-1.5vw',
  height: '1.5vw',
  backgroundImage: 'url(redstoneblock.png)',
  backgroundSize: 'cover',
  color: 'white'
};


const CLAUSE_WIDTH = 300;


export default function Clause({ clause, setClause, x: x0, y: y0 }) {
  const collisionHandler = window.collisionHandler;

  const [x, setX] = useState(x0);
  const [y, setY] = useState(y0);

  const onTriggerEnter = (metadata, sameCollider) => {
    if (!sameCollider) {
      const newClause = {
        ...clause,
        statements: [...clause.statements, metadata]
      };
      setClause(clause.id, newClause);
    }
  };

  const onTriggerLeave = (metadata, sameCollider) => {
    if (!sameCollider) {
      const newClause = {
        ...metadata.clause,
        statements: metadata.clause.statements.filter(statement => statement.id !== metadata.id)
      };
      setClause(metadata.clause.id, newClause);
    }
  };

  collisionHandler.setCollider(
    { x, y, width: CLAUSE_WIDTH, height: 250 },
    clause.id,
    onTriggerEnter,
    onTriggerLeave
  );
  collisionHandler.setColliderPosition(clause.id, x, y);

  const setPosition = (_, event) => {
    const boundingBox = event.node.getBoundingClientRect();
    setX(boundingBox.x);
    setY(boundingBox.y);
  };

  const dropStatement = (event, statement) => {
    const { x, y } = event.node.getBoundingClientRect();
    collisionHandler.doCollision(
      { x, y, width: statement.width, height: statement.height },
      statement.id,
      { ...statement, clause }
    );
  };

  const statements = clause.statements.map(statement => (
    <li key={statement.id} style={{ display: 'inline-block', marginBottom: '5px' }}>
      <Statement statement={statement} onDrop={dropStatement} />
    </li>
  ));

  let header;
  switch (clause.condition.type) {
    case 'options':
      header = <OptionsTitle
        title={clause.condition.text}
        options={clause.condition.options}
        onSelect={(value, type) => {
          const hook = clause.condition.hooks[type];
          if (hook)
            hook(value, clause.id)
        }}
      />
      break;
    case 'recipe':
      const setItem = (item, index) => {
        if (!clause.data.recipe)
          clause.data.recipe = new Array(9).fill(null)
        clause.data.recipe[index] = item;
        console.log(clause)
      }
      const setName = (name) => {
        if (!clause.data.result)
          clause.data.result = {};
        clause.data.result.name = name;
      };
      const setAmount = (amount) => {
        if (!clause.data.result)
          clause.data.result = {};
        clause.data.result.count = amount;
      };
      const setResult = (item) => {
        if (!clause.data.result)
          clause.data.result = {};
        clause.data.result.item = item;
      };
      header = <div>
        <Typography style={textStyle} variant="h6">
          Craft Item
        </Typography>
        <Crafting
          onItem={setItem} onName={setName}
          onAmount={setAmount} onResult={setResult}
        />
      </div>
      break;
    default:
      header = <Typography style={textStyle} variant="h6">
        {clause.condition.text}:
      </Typography>
      break;
  }

  return (
    <Draggable cancel='.noDrag' defaultPosition={{ x, y }} onStop={setPosition}>
      <div style={style(CLAUSE_WIDTH)} onContextMenu={console.log}>
        {header}
        <ul className='noDrag' style={listStyle}>
          {statements}
        </ul>
        <Button style={buttonStyle} onClick={() => window.removeClause(clause.id)}>X</Button>
      </div>
    </Draggable >
  );
}