import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const THEME = createMuiTheme({
  typography: {
    "fontFamily": `Minecraftia`,
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={THEME}>
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
);
