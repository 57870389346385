import { Card } from "@material-ui/core";
import Draggable from 'react-draggable';

import OptionsTitle from '../OptionsTitle';

const style = (width, height) => ({
  cursor: 'pointer',
  width: `${width}px`,
  // height: `${height}px`,
  color: 'white',
  backgroundImage: 'linear-gradient(rgba(256,256,256,.15), rgba(256,256,256,.15)), url(./brick.png)',
  backgroundSize: 'cover',
});

export default function OptionsStatement({ statement, onDrop }) {
  return (
    <Draggable onStop={(_, event) => onDrop(event, statement)}>
      <Card style={style(statement.width, statement.height)} >
        <OptionsTitle
          title={statement.text} options={statement.options}
          onSelect={(option, type) => statement.data[type] = option} />
      </Card>
    </Draggable>
  );
}
