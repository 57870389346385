import { Button } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';

import { conditions, statements } from '../utils/datapack';

import "../styles/fonts.css";

const style = {
  'background-color': '#408F49C0',
  'padding-top': '10px',
  width: '100%'
};

const divStyle = {
  'padding-bottom': '12px',
  display: 'flex',
  'justify-content': 'center',
};

const buttonStyle = {
  'background-color': 'rgba(0,0,0,.25)',
  'margin': '1px 2px 1px 2px',
  color: '#DfDfDf'
};

export default function Menu({ addCondition, addStatement }) {
  return (
    <div style={style}>
      <div style={divStyle}>
        {
          conditions.map(condition => (
            <Button style={buttonStyle} key={uuidv4()} onClick={() => addCondition(condition)}>
              {condition.text}.
            </Button>
          ))
        }
      </div>
      <div style={divStyle}>
        {
          statements.map(statement => (
            <Button style={buttonStyle} key={uuidv4()} onClick={() => addStatement(statement)}>
              {statement.title}
            </Button>
          ))
        }
      </div>
    </div>
  );
}
