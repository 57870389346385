const socket = new WebSocket('wss://g9b6zoixo5.execute-api.us-east-2.amazonaws.com/development?session=new');

socket.onopen = () => console.log('connected');
socket.onerror = (e) => console.log('error', e);
socket.onclose = () => console.log('close');

const subscribe = (cb) => {
  socket.onmessage = ({ data }) => cb(JSON.parse(data));
};

const send = json => {
  socket.send(JSON.stringify(json));
};

export { subscribe, send };
