import { TextField as TextBruh } from '@material-ui/core/';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { itemList } from '../utils/datapack';


const StyledText = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
  },
  input: {
    color: 'white'
  }

})(TextBruh);

function Dropdown({ list, onSelect, placeHolder, style }) {
  return (
    <Autocomplete
      options={list}
      style={{ ...style }}
      onChange={(_, newValue) => onSelect(newValue)}
      disableClearable={true}
      renderInput={(params) => <StyledText {...params} label={placeHolder} />}
    />
  );
}

function TextField({ placeHolder, onChange, style }) {
  return (
    <StyledText
      placeholder={placeHolder}
      fullWidth={true}
      style={{ color: 'white', ...style }}
      onChange={(event) => onChange(event.target.value)}
    />
  );
}

export default function Crafting({ onChange, onItem, onResult, onName, onAmount }) {

  const gridStyle = { display: 'inline-block', width: '33.333%' };
  const craftingGrid = <>
    <div>
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 0)} style={gridStyle} />
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 1)} style={gridStyle} />
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 2)} style={gridStyle} />
    </div>
    <div>
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 3)} style={gridStyle} />
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 4)} style={gridStyle} />
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 5)} style={gridStyle} />
    </div>
    <div>
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 6)} style={gridStyle} />
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 7)} style={gridStyle} />
      <Dropdown list={itemList} onSelect={(item) => onItem(item, 8)} style={gridStyle} />
    </div>
  </>;

  return (
    <>
      {craftingGrid}
      <Dropdown list={itemList} onSelect={onResult} />
      <TextField placeHolder='Item Name' onChange={onName}
        style={{ display: 'inline-block', width: '75%' }} />
      <TextField placeHolder='Amount' onChange={onAmount}
        style={{ display: 'inline-block', width: '25%' }} />
    </>
  );
}