let colliders = []
let parents = [];

function forceCollision(colliderId, collisionId, metadata) {
  const index = colliders.findIndex(collider => collider.id === colliderId);
  parents[collisionId] = colliders[index];
}

function checkCollision(rectA, colliders, collisionId) {
  for (let i = 0; i < colliders.length; i++) {
    const collider = colliders[i];
    if (collisionId !== collider.id) {
      if (rectA.x < collider.x + collider.width &&
        rectA.x + rectA.width > collider.x &&
        rectA.y < collider.y + collider.height &&
        rectA.y + rectA.height > collider.y) {
        return { collided: true, collider };
      }
    }
  }

  return { collided: false };
}

function doCollision(collider, collisionId, metadata) {
  const { collided, collider: newCollider } = checkCollision(collider, colliders, collisionId);

  const oldCollider = parents[collisionId];
  if (collided) parents[collisionId] = newCollider;
  else delete parents[collisionId]

  const sameCollider = oldCollider.id === newCollider.id;
  const res = oldCollider?.onLeave(metadata, sameCollider);
  if (res !== 'skip')
    newCollider?.onTrigger(metadata, sameCollider);

  return collided;
};

function setCollider(boundingBox, id, onTrigger, onLeave, index = 0) {
  const newCollider = {
    ...boundingBox,
    id,
    onTrigger,
    onLeave,
    index
  };


  if (colliderExists(id)) {
    const index = colliders.findIndex(collider => collider.id === id);
    colliders[index] = newCollider

    return false;
  }

  colliders.push(newCollider);
  colliders = colliders.sort((a, b) => b.index - a.index);

  return newCollider.id;
};

function setColliderPosition(id, x, y) {
  const index = colliders.findIndex(collider => collider.id === id);
  colliders[index] = {
    ...colliders[index],
    x, y
  };
};

function colliderExists(id) {
  return colliders.find((collider) => collider.id === id);
}

function removeCollider(id) {
  colliders = colliders.filter(collider => collider.id !== id);
};

export const collisionHandler = {
  forceCollision, setCollider, doCollision, setColliderPosition, colliderExists, removeCollider
};