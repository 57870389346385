import { useState } from "react";

import Board from "./Components/Board";
import Menu from './Components/Menu';
import { Button } from "@material-ui/core";

import { collisionHandler } from './utils/collision';
import { v4 as uuid4 } from "uuid";
import { saveClauses } from './utils/datapack';

import './styles/fonts.css';
import './utils/socket';


window.collisionHandler = collisionHandler;

function createClause(condition) {
  return {
    id: uuid4(),
    condition: { ...condition },
    data: { ...condition.data },
    statements: []
  }
}

function createStatement(statement) {
  return {
    ...statement,
    data: { ...statement.data },
    id: uuid4(),
  }
}

const buttonStyle = {
  position: 'fixed',
  bottom: '0%',
  backgroundImage: 'url(goldblock.webp)',
  backgroundSize: 'cover',
};


export default function App() {
  const [value, setValue] = useState(0);
  window.rerender = () => setValue(value => value + 1);;

  const [clauses, setClauses] = useState([]);
  const [floatingStatements, setFloatingStatements] = useState([]);

  const addCondition = (condition) => setClauses([...clauses, createClause(condition)]);
  const addStatement = (statement) => setFloatingStatements(prevStatements =>
    [...prevStatements, createStatement(statement)]
  );

  const setClause = (id, clause) => {
    setClauses(prevClauses => {
      const newClauses = [...prevClauses];
      const index = newClauses.findIndex((clause) => clause.id === id);
      newClauses[index] = clause;

      return newClauses;
    });
  };

  const floatStatement = (statement) => setFloatingStatements(prevStatements =>
    [...prevStatements, statement]
  );

  const deFloatStatement = (id) => {
    let statement;

    setFloatingStatements(prevStatements => {
      const index = prevStatements.findIndex(statement => statement.id === id);
      statement = prevStatements[index];

      const newStatements = prevStatements
        .filter((statement) => statement.id !== id);

      return newStatements;
    });

    return statement;
  };


  window.removeClause = (id) => {
    setClauses(prevClauses => {
      return prevClauses.filter(clause => clause.id !== id);
    });
    collisionHandler.removeCollider(id);
  };

  return (
    <>
      <Menu addCondition={addCondition} addStatement={addStatement} />
      <Board
        clauses={clauses}
        floatingStatements={floatingStatements}
        setClause={setClause}
        floatStatement={floatStatement}
        deFloatStatement={deFloatStatement}
      />
      <Button style={buttonStyle}
        onClick={() => saveClauses(clauses)}>
        Save
      </Button>
    </>
  );

}
