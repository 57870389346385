import { Card } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';

const style = (width, height) => ({
  cursor: 'pointer',
  width: `${width}px`,
  height: `${height}px`,
  color: 'white',
  backgroundImage: 'linear-gradient(rgba(256,256,256,.15), rgba(256,256,256,.15)), url(./brick.png)',
  backgroundSize: 'cover',
});

export default function DefaultStatement({ statement, onDrop }) {
  return (
    <Draggable onStop={(_, event) => onDrop(event, statement)}>
      <Card style={style(statement.width, statement.height)} >
        <Typography variant="h6" style={{ userSelect: "none", fontSize: '16px' }}>
          {statement.text}
        </Typography>
      </Card>
    </Draggable>
  );
}
